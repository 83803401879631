(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/sportinfo/assets/javascripts/sportinfo.js') >= 0) return;  svs.modules.push('/components/sportinfo/sportinfo/assets/javascripts/sportinfo.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useEffect,
  useState
} = React;
const {
  Switch,
  Route,
  useLocation,
  useHistory
} = ReactRouterDOM;
const {
  getSelectedOnPaths,
  regexSportinfoPathnames
} = svs.components.sportinfo.common.constants;
const {
  ItemsMenu
} = svs.ui.ReactItemsMenu;
const {
  LinkItemRoute
} = svs.components.lbUtils.linkItemRoute;
const {
  getDefaultRoute
} = svs.components.sportinfo.configs.ContentConfig;
const {
  SportinfoContentView
} = svs.components.sportinfo.sportinfoContentView;
const {
  useExternalMatchIds
} = svs.components.sport.statisticsCommon;
const Sportinfo = _ref => {
  let {
    basePath = '/',
    matchId,
    branding,
    className,
    eventNumber,
    productId,
    FallbackComp,
    activeTabBasePaths = [],
    appendTabSearchParams,
    tabComponent,
    tabAreaClassName,
    drawNumber,
    setActiveTab,
    setCoupon,
    isWideDevice,
    matchState,
    menuItems = [],
    content,
    theme,
    sportType,
    menuOptions = {
      hiddenScrollbar: true,
      useScrollFaders: false,
      useScrollHelpers: false
    },
    eventTypeId = 1,
    lazyComponentsMap
  } = _ref;
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const isSportinfoPathname = Boolean(location.pathname.match(regexSportinfoPathnames));

    const menuItemsPathnames = menuItems.map(item => item.to.pathname).filter(item => item !== '');
    const regexMenuItems = new RegExp("(".concat(menuItemsPathnames.join('|'), ")$"));
    const isMenuItemAvailable = Boolean(location.pathname.match(regexMenuItems));
    if (menuItems.length > 0 && isSportinfoPathname && !isMenuItemAvailable) {
      const pathname = location.pathname.replace(regexSportinfoPathnames, '');
      history.replace({
        pathname,
        search: location.search
      });
    }
  }, [menuItems]);
  const isModalOpen = document.querySelector('.lb-modal-is-open');
  const renderItemsMenu = (isModalOpen || isWideDevice) && menuItems.length > 0;
  const mutualsElementClassName = 'mutuals';
  const {
    externalId
  } = useExternalMatchIds(matchId);
  const activeMenuItem = document.querySelector('.items-menu-active');
  useEffect(() => {
    if (activeMenuItem) {
      activeMenuItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [activeMenuItem]);
  const [oldDefaultRoute, setOldDefaultRoute] = useState();
  useEffect(() => {
    if (sportType && matchState) {
      const defaultRoute = getDefaultRoute({
        sportType,
        matchState,
        providerIds: externalId,
        productId
      });
      if (oldDefaultRoute !== defaultRoute) {
        if (defaultRoute) {
          const url = {
            pathname: "".concat(basePath).concat(defaultRoute),
            search: location.search
          };
          url.pathname = url.pathname.replace(/\/\/+/, '/');
          history.replace(url);
        }
        setOldDefaultRoute(defaultRoute);
      }
    }
  }, [sportType, matchState, externalId, productId]);
  const itemsMenuClassNames = ['side-nav-statistics-menu'];
  if (branding) {
    itemsMenuClassNames.push(branding);
  }
  return React.createElement(React.Fragment, null, renderItemsMenu ? React.createElement(ItemsMenu, {
    branding: "statistics-nav",
    className: itemsMenuClassNames.join(' '),
    hiddenScrollbar: menuOptions.hiddenScrollbar,
    useScrollFaders: menuOptions.useScrollFaders,
    useScrollHelpers: menuOptions.useScrollHelpers
  }, menuItems.map(_ref2 => {
    let {
      label,
      testIdSuffix,
      to,
      componentKey
    } = _ref2;
    const dataTestId = testIdSuffix === mutualsElementClassName ? mutualsElementClassName : "statistic-menu-".concat(testIdSuffix);
    return React.createElement(LinkItemRoute, _extends({}, tabComponent ? {
      Component: tabComponent
    } : {}, {
      "data-test-id": dataTestId,
      key: componentKey,
      label: label,
      onClick: setActiveTab && (() => setActiveTab(label)),
      selectedOnPaths: getSelectedOnPaths(activeTabBasePaths, componentKey),
      to: _objectSpread(_objectSpread({}, to), {}, {
        search: appendTabSearchParams ? String(appendTabSearchParams) : ''
      })
    }));
  })) : React.createElement("div", {
    className: "items-menu-placeholder"
  }), React.createElement("div", tabAreaClassName ? {
    className: tabAreaClassName
  } : {}, React.createElement(Switch, null, menuItems && menuItems.map(_ref3 => {
    let {
      componentKey,
      getPath,
      to
    } = _ref3;
    return React.createElement(Route, {
      exact: to.pathname === '',
      key: componentKey,
      path: getPath(basePath),
      render: () => React.createElement(SportinfoContentView, {
        branding: branding,
        className: className,
        content: content[componentKey],
        drawNumber: drawNumber,
        eventNumber: eventNumber,
        eventTypeId: eventTypeId,
        FallbackComp: FallbackComp,
        lazyComponentsMap: lazyComponentsMap,
        matchId: matchId,
        productId: productId,
        setCoupon: setCoupon,
        sportType: sportType,
        theme: theme
      })
    });
  }))));
};
setGlobal('svs.components.sportinfo.sportinfo.Sportinfo', Sportinfo);

 })(window);