(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/sportinfo/assets/javascripts/helpers.js') >= 0) return;  svs.modules.push('/components/sportinfo/sportinfo/assets/javascripts/helpers.js');
'use strict';

const themeClass = theme => {
  switch (theme) {
    case 'dark':
      return 'big-stat-theme-dark';
    default:
      return 'big-stat-theme-light';
  }
};
setGlobal('svs.components.sportinfo.sportinfo.helpers.themeClass', themeClass);

 })(window);